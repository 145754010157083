import Pusher from 'pusher-js/with-encryption'
import { ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import config from '@/config'
import axios from 'axios'
export default function subscribeChatChannel() {
  const store = useStore()
  const router = useRouter()
  const route = useRoute()

  const pusherId = config.pusherId
  const cluster = config.cluster
  const authEndpoint = config.baseUrl + '/webchat/pusher/auth'

  const inputMessage = ref('')
  const files = ref({})

  let chatCount = 0

  onBeforeMount(() => {
    if (route.name == 'checkAgent') {
      const pusher = new Pusher(pusherId, {
        cluster: cluster,
        authEndpoint: authEndpoint,
        forceTLS: true,
      })
      const channelId = generateSession(20)
      const channel = pusher.subscribe(`private-${channelId}`)
      channel.bind('pusher:subscription_succeeded', () => {
        store.commit('updateChannel', channel)
        store.commit('updateChannelId', channelId)
        store.commit('updateSocketId', pusher.connection.socket_id)
        checkAgentAvailable(router)
      })
      channel.bind('pusher:subscription_error', (error) => {
        console.log('pusher:subscription_error', error)
        router.push({ name: 'ErrorPage' })
      })
    } else {
      const channel = store.getters.getChannel
      channel.bind('my-event', (data) => {
        store.commit('updateMessageType', data.messageType)
        if (data.messageType === 'end chat') {
          router.push(`/survey/${store.getters.getsessionId}/th`)
        }
        if (data.sender == 'customer') {
          store.commit('updateMyMessages', data)
        } else {
          if (data.fileSize) {
            data.fileSize = formatBytes(data.fileSize)
          }
          window.parent.postMessage(
            {
              agentSentMessage: true,
            },
            '*'
          )
          store.commit('updateStoreMessages', data)
          if (data.messageType == 'end chat without survey') {
            store.commit('updateIsEndChat', true)
          }
          clearTimeout(countDownMsg)
        }
      })
      channel.bind('client-my-event', (data) => {
        store.commit('updateMyMessages', data)
      })
      let countDownMsg = setTimeout(() => {
        let data = {
          websession_id: `private-${store.getters.getChannelId}`,
        }
        axios.post(config.baseUrl + '/webchat/waiting', data).then(() => {
          console.log('sent waiting message')
        })
      }, 60000)
    }
  })

  function generateSession(length) {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let str = ''
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length))
    }

    return btoa(str)
  }

  function formatBytes(size) {
    size = parseInt(size)
    let units = ['B', 'KB', 'MB', 'GB', 'TB'],
      i

    for (i = 0; size >= 1024 && i < 4; i++) {
      size /= 1024
    }

    return size.toFixed(2) + ' ' + units[i]
  }

  function checkAgentAvailable(router) {
    axios
      .get(`${config.baseUrl + config.checkAgentEndpoint}`)
      .then((res) => {
        // || res.data.agent_status === 0
        if (res.status == 200) {
          if (res.data.agent_status >= 1) {
            router.push({
              name: 'PreScreen',
            })
          } else {
            router.push('sorry-page')
          }
        } else {
          router.push('error-page')
        }
      })
      .catch((error) => {
        console.log(error)
        router.push('error-page')
      })
  }
  async function sendForm(msg) {
    // if (
    //   !/[A-Za-z0-9ก-ฮ!@#$%^&*()-_=+*<>/]+/g.test(inputMessage.value) &&
    //   files.value.length == undefined
    // ) {
    //   inputMessage.value = ''
    //   return
    // }
    if (inputMessage.value === '' && files.value.length == undefined) {
      return
    }
    console.log(files)
    if (files.value.length) {
      const imgPath = await uploadImage()
      msg.message = imgPath.data.path
      if (files.value[0].type.indexOf('image') > -1) {
        msg.messageType = 'image'
      } else {
        msg.messageType = 'file'
      }
      files.value = {}
      document.getElementById('uploadFile').value = ''
      sendMessage(msg)
    }
    if (inputMessage.value !== null && inputMessage.value !== '') {
      let msg = {
        message: inputMessage.value,
        sender: 'customer',
        messageType: 'text',
        uploading: true,
        chatNum: chatCount,
      }
      store.commit('updateStoreMessages', msg)
      sendMessage(msg)
      inputMessage.value = ''
    }
  }

  function uploadImage() {
    return new Promise((resolve, reject) => {
      var formdata = new FormData()
      formdata.append('photos', files.value[0])
      axios
        .post(`${config.baseUrl}/webchat/upload/`, formdata, {
          headers: { 'content-type': 'multipart/form-data' },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  function onFileChange(e) {
    files.value = e.target.files || e.dataTransfer.files
    if (!files.value.length) {
      return
    }
    let allowedPath = /(\.jpg|\.jpeg|\.png|\.pdf)$/i
    let filePath = e.target.value
    if (files.value[0].size > 5 * 1024 * 1024 || !allowedPath.exec(filePath)) {
      console.log('Send Image Error')
      let msg = {
        sender: 'image-error',
      }
      store.commit('updateStoreMessages', msg)
      document.getElementById('uploadFile').value = ''
      return
    }

    let size = formatBytes(files.value[0].size)
    const path = new FileReader()

    path.addEventListener('load', (e) => {
      var msg = {}
      if (files.value[0].type.indexOf('image') > -1) {
        msg = {
          message: e.target.result,
          fileName: files.value[0].name,
          fileSize: size,
          sender: 'customer',
          messageType: 'image',
          uploading: true,
          chatNum: chatCount,
        }
      } else {
        msg = {
          message: e.target.result,
          fileName: files.value[0].name,
          fileSize: size,
          sender: 'customer',
          messageType: 'file',
          uploading: true,
          chatNum: chatCount,
        }
      }
      store.commit('updateStoreMessages', msg)
      sendForm(msg)
    })

    path.readAsDataURL(files.value[0])
  }

  async function sendMessage(msg) {
    msg.date = new Date()
    msg.social_type = 'ttb Website'
    const channel = store.getters.getChannel
    channel.trigger('client-my-event', msg)
    chatCount++
  }

  return { inputMessage, onFileChange, sendForm }
}
